import React, { useContext } from 'react'

import { StatsigProvider, useClientAsyncInit } from '@statsig/react-bindings'

import type { ExperimentsStore } from 'shared/stores/experiments.store'
import { UserSessionContext } from './user-session-provider'

type StatsigUserPrimitives = string | number | boolean | Array<string> | undefined

export const ExperimentsAndFeatureGatesProvider: React.FC<{
  children: React.ReactNode
  clientKey: string
  experimentsStore: ExperimentsStore
  customProperties: Record<string, StatsigUserPrimitives>
}> = ({ children, clientKey, experimentsStore, customProperties }) => {
  if (customProperties.sellerId) {
    const { userSessionId } = useContext(UserSessionContext)
    const environment = { tier: process.env.ENV }

    const user = {
      userID: userSessionId,
      statsigEnvironment: environment,
      custom: customProperties ?? {},
    }

    const { client } = useClientAsyncInit(clientKey, user)

    experimentsStore.setClient(client)

    return (
      <StatsigProvider sdkKey={clientKey} client={client} user={user}>
        {children}
      </StatsigProvider>
    )
  }
}
