import { action, observable, toJS, makeObservable } from 'mobx'
import { merge } from 'utils/lodash.utils'

import { debounceEvent } from 'utils/helpers.utils'
import { arrayLast } from 'utils/array.utils'
import { isBlockContentEmpty } from '../utils/block.utils'

import { BLOCK_DEFAULT_VALUES } from '../constants/block.constants'
import { BUILDER_FIELD_TYPES } from '../constants/pageBuilder.constants'

export class ContentBlockStore {
  @observable editableBlock = null
  @observable showEditCloseConfirm = false
  @observable deleteModalId = ''
  @observable undoArray = []
  @observable presentBlock = {}
  @observable redoArray = []
  @observable hiddenBlocksNotifics = {}

  @action updateDeleteId = (value) => (this.deleteModalId = value)

  @action hideBlockNotific = (blockId) => {
    this.hiddenBlocksNotifics = {
      ...this.hiddenBlocksNotifics,
      [blockId]: true,
    }
  }

  @action updateUndoArray = (value, ownValue) => {
    if (ownValue) {
      this.undoArray = value
    } else {
      this.undoArray.push(value)
    }
  }

  @action removeElFromUndoArray = () => this.undoArray.pop()

  @action updateRedoArray = (value, ownValue) => {
    if (ownValue) {
      this.redoArray = value
    } else {
      this.redoArray.push(value)
    }
  }

  @action removeElFromRedoArray = () => this.redoArray.pop()

  @action updatePresentBlock = (value) => {
    this.presentBlock = {
      ...this.presentBlock,
      ...value,
    }
  }

  @action resetUndoArray = () => (this.undoArray = [])
  @action resetRedoArray = () => (this.redoArray = [])
  @action resetPresentBlock = () => (this.presentBlock = {})

  @action toggleEditCloseConfirm = (callback) => {
    // set present editable block to array before close edit mode for block
    this.updatePresentBlock({
      [this.editableBlock?.id]: this.editableBlock,
    })
    this.editableBlock = null

    if (callback) {
      callback()
    }
  }

  @action switchBlockEdit = (block) => {
    if (block) {
      if (this.editableBlock) {
        if (block.id !== this.editableBlock.id) {
          this.toggleEditCloseConfirm(() => this.switchBlockEdit(block))
        }
        this.updatePresentBlock({
          [block.id]: this.editableBlock,
        })
      } else {
        const content = isBlockContentEmpty(block.content)
          ? BLOCK_DEFAULT_VALUES[block.form]
          : {
              ...BLOCK_DEFAULT_VALUES[block.form],
              ...block.content,
            }
        this.editableBlock = {
          ...block,
          content,
        }

        this.updatePresentBlock({
          [this.editableBlock?.id]: this.editableBlock,
        })
      }
    } else {
      // set present editable block to array before switch edit to another block
      this.updatePresentBlock({
        [this.editableBlock?.id]: this.editableBlock,
      })
      this.editableBlock = null
    }
  }

  @action undoAction = () => {
    if (this.undoArray.length) {
      this.setBlockContentData('', '', {
        undo: true,
        redo: false,
      })
    }
  }

  @action redoAction = () => {
    if (this.redoArray.length) {
      this.setBlockContentData('', '', {
        undo: false,
        redo: true,
      })
    }
  }

  updateFunnelProduct = (funnelProduct, noButton, yesButton) => {
    const { updateContentBlock } = this.root.contentPageStore
    const productId = yesButton.data.product.productId
    const pricingPlanId = yesButton.data.pricingPlanId
    const dataToUpdate = {
      removeData: {
        productId,
        pricingPlanId,
      },
    }
    const productData = this.root.productsStore.list.find((val) => String(val.id) === String(productId))
    const priceData = this.root.pricingPlansStore.list.find((val) => String(val.id) === String(pricingPlanId))
    const { convertToPrice } = this.root.currenciesStore

    updateContentBlock(funnelProduct.id, {
      data: {
        ...funnelProduct.data,
        ...dataToUpdate,
        ...(productData
          ? {
              product: {
                name: productData.name,
                description: productData.description,
                coverUrl: productData.covers[0]?.file.s640 || '',
                pricingPlanPrice: convertToPrice(
                  priceData.prefs.customIntervals
                    ? priceData.prefs.rate1Amount
                    : priceData.prefs.price ?? priceData.prefs.firstAmount ?? 0,
                  priceData.currencyId
                ),
                pricingPlanForm: priceData.form,
              },
            }
          : {}),
      },
    })
    updateContentBlock(noButton.id, {
      data: {
        ...noButton.data,
        ...dataToUpdate,
      },
    })
    !yesButton.data.removeData &&
      updateContentBlock(yesButton.id, {
        data: {
          ...yesButton.data,
          ...dataToUpdate,
        },
      })
  }

  handleEditableBlockSave = ({ undo, redo, callback, contactFormId, value }) => {
    const undoDataLastItem = arrayLast(this.undoArray) || {}
    const redoDataLastItem = arrayLast(this.redoArray) || {}

    const undoBlockData = undo ? undoDataLastItem : this.editableBlock

    const editableBlock = redo ? redoDataLastItem : undoBlockData

    const undoEditableContent = undo ? undoDataLastItem?.content : this.editableBlock?.content

    let editableContent = redo ? redoDataLastItem?.content : undoEditableContent

    if (editableBlock.form === 'popular_products') {
      editableContent = {
        ...editableContent,
        productIds: toJS(editableContent.productIds),
      }
    }

    if (editableBlock.form === 'testimonials') {
      editableContent = {
        ...editableContent,
        testimonials: toJS(editableContent.testimonials),
      }
    }

    if (editableBlock.form === 'header' || editableBlock.form === 'footer') {
      editableContent = {
        ...editableContent,
        menuList: toJS(editableContent.menuList),
      }
    }
    this.root.contentPageStore
      .updateContentBlock(editableBlock.id, {
        data: toJS(editableContent),
        embeddableItemId: editableBlock.embeddableItemId,
        coverId: editableBlock.coverId,
        ...(editableBlock.dragAndDropped && {
          parentId: editableBlock.parentId,
          position: editableBlock.position + (undoDataLastItem.position || 0),
        }),
      })
      .then((resp) => {
        if (resp.success) {
          this.root.contentPageStore.contentBlocks = merge(
            resp.data.contentBlocks,
            this.root.contentPageStore.data.contentBlocks
          )
          // contentPageStore.refetchContentPage({ hideLoading: true })

          if (editableBlock.form === 'funnel') {
            const funnelYesButton = resp.data.contentBlocks
              .find(({ id }) => id === editableBlock.parentId)
              .children.find(({ id, data }) => id === editableBlock.id && data.type === 'yes')
            const emptyFunnelProduct = resp.data.contentBlocks
              .map((block) =>
                block.children.filter(
                  (val) => val.form === 'funnel_product' && !val.data.product && !val.data.removeData
                )
              )
              .flat()[0]
            const emptyNoButton = resp.data.contentBlocks
              .map((block) =>
                block.children.filter((val) => val.form === 'funnel' && val.data.type === 'no' && !val.data.removeData)
              )
              .flat()[0]

            if (
              emptyFunnelProduct?.id &&
              emptyNoButton?.id &&
              funnelYesButton?.data?.product?.productId &&
              funnelYesButton.data.pricingPlanId
            ) {
              this.updateFunnelProduct(emptyFunnelProduct, emptyNoButton, funnelYesButton)
            }
          }
        }

        if (callback) {
          callback(contactFormId, {
            name: value,
          })
        }
      })

    // cancel last action and delete this value from array
    if (undo) {
      // apply current changes when block form changed
      this.updatePresentBlock({
        [editableBlock.id]: editableBlock,
      })
      this.removeElFromUndoArray()
    }

    // return canceled action and delete this value from array
    if (redo) {
      // apply current changes when block form changed
      this.updatePresentBlock({
        [editableBlock.id]: editableBlock,
      })
      this.removeElFromRedoArray()
    }
  }

  handleEditableBlockSaveWithDebounce = debounceEvent(this.handleEditableBlockSave, 500)

  @action setBlockContentData = (field, value, { undo, redo, fieldKey, callback, contactFormId } = {}) => {
    const undoDataLastItem = arrayLast(this.undoArray) || {}
    const redoDataLastItem = arrayLast(this.redoArray) || {}

    // undo array or present
    const undoData = undo ? undoDataLastItem : this.editableBlock

    // redo array or get undoData
    const redoData = redo ? redoDataLastItem : undoData

    const { isCreated, createOnUndo } = redoData || {}

    // add last change to undo array and reset redo array to [] because you can't move further
    if (!undo && !redo) {
      this.updateUndoArray(this.editableBlock)
      this.resetRedoArray()
    }

    // when element was created and clicking cmd+z show modal window with confirm about delete
    if ((isCreated && undo) || (createOnUndo && redo)) {
      const deleteData = createOnUndo && redo ? redoDataLastItem : undoDataLastItem
      this.updateDeleteId(deleteData?.contentBlockId)
    }

    // when element was deleted and clicking cmd+y/cmd+shift+z you create this element again
    if ((isCreated && redo) || (createOnUndo && undo)) {
      const needToCreateOnUndo = createOnUndo && undo
      const createData = needToCreateOnUndo ? undoDataLastItem : redoDataLastItem
      const { id, parentId, form, position, coverId, contentBlockId } = createData || {}
      const { contentBlocks = [] } = this.root.contentPageStore.data || {}
      const parentIdsValue = contentBlocks.map((item) => {
        let isParentIdExist = false
        item.children.forEach((children) => {
          if (String(children.parentId) === String(parentId)) {
            isParentIdExist = true
          }
        })
        return isParentIdExist
      })
      const getRedoArray = (data) =>
        this.redoArray.map((item) => {
          if (contentBlockId === (item.id || item.contentBlockId)) {
            return {
              ...item,
              id: data.contentBlockId,
              contentBlockId: data.contentBlockId,
            }
          }
          return {
            ...item,
          }
        })

      this.root.contentPageStore
        .createContentBlock(arrayLast(parentIdsValue) ? parentId : '', form, position, {
          ...BLOCK_DEFAULT_VALUES[form],
          ...createData.content,
          coverId,
        })
        .then((resp) => {
          const { data = {}, success } = resp

          if (success) {
            if (needToCreateOnUndo) {
              // update undo array if you want delete block after return
              this.updateRedoArray({
                ...undoDataLastItem,
                contentBlockId: data.contentBlockId,
              })
              this.removeElFromUndoArray()
            } else {
              // update undo array if you want delete block after return
              this.updateUndoArray({
                ...redoDataLastItem,
                contentBlockId: data.contentBlockId,
              })
              this.removeElFromRedoArray()
            }
          }
          if (needToCreateOnUndo) {
            // after creating new element id is not the same as before and map here and override blockId to new one
            const undoArray = getRedoArray(data)

            const presentBlock = {
              ...this.presentBlock[id],
              ...this.presentBlock[contentBlockId],
              id: data.contentBlockId,
              contentBlockId: data.contentBlockId,
            }
            // not need to push one more element to redo array, only change all value in array for the same values but with new blockId
            this.updateUndoArray(undoArray, true)
            // the same as for redo, only update id for present object
            this.updatePresentBlock({
              [data.contentBlockId]: presentBlock,
            })
          } else {
            // after creating new element id is not the same as before and map here and override blockId to new one
            const redoArray = getRedoArray(data)
            const presentBlock = {
              ...this.presentBlock[id],
              ...this.presentBlock[contentBlockId],
              id: data.contentBlockId,
              contentBlockId: data.contentBlockId,
            }
            // not need to push one more element to redo array, only change all value in array for the same values but with new blockId
            this.updateRedoArray(redoArray, true)
            // the same as for redo, only update id for present object
            this.updatePresentBlock({
              [data.contentBlockId]: presentBlock,
            })
          }
        })
    }

    // working flow with block which where created before when you open PB
    if (!isCreated && !createOnUndo) {
      // cancel last action and update redo array
      if (undo) {
        const { id } = this.editableBlock || {}
        // write correct data when changes relate to other block form
        if (id !== undoData.id) {
          const redoDataAfterDragAndDrop = {
            ...undoData,
            parentId: undoData.redoParentId,
          }
          this.updateRedoArray(this.presentBlock[undoData.id] || redoDataAfterDragAndDrop)
        } else {
          this.updateRedoArray({
            ...this.editableBlock,
            ...(undoData.dragAndDropped && {
              parentId: undoData.redoParentId,
              dragAndDropped: undoData.dragAndDropped,
            }),
          })
        }
      }

      // return future action and update undo array
      if (redo) {
        const { id } = this.editableBlock || {}
        if (id !== redoData.id) {
          this.updateUndoArray(this.presentBlock[redoData.id])
        } else {
          this.updateUndoArray(this.editableBlock)
        }
      }

      const newValue = field && typeof field !== 'object' ? { [field]: value } : {}
      const isIncludeContent = typeof field === 'object' && Object.keys(field).includes('content')

      this.editableBlock = {
        ...redoData,
        ...(typeof field === 'object' ? { ...field } : {}),
        content: {
          ...redoData?.content,
          ...newValue,
          ...(isIncludeContent && { ...field.content }),
        },
      }

      if (fieldKey === BUILDER_FIELD_TYPES.addText || fieldKey === BUILDER_FIELD_TYPES.formName) {
        this.handleEditableBlockSaveWithDebounce({
          undo,
          redo,
          callback,
          contactFormId,
          value,
        })
      } else {
        this.handleEditableBlockSave({
          undo,
          redo,
        })
      }
    }
  }

  @action setBlockParams = (name, value, withSave) => {
    this.editableBlock = {
      ...this.editableBlock,
      [name]: value,
    }
    if (withSave) {
      this.handleEditableBlockSave({})
    }
  }

  constructor(rootStore) {
    this.root = rootStore
    makeObservable(this)
  }
}
